import axios from "axios"
import WebFont from "webfontloader"
import { commonAxios } from "../global/CommonAxios"
import { setAlert } from "./alert"

export const onCreateName = (data, history, setLoader) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {
                    campaignName: data.campaignName,
                    campaignId: res.data
                }
                dispatch({ type: "SET_CAMPAIGN_NAME", payload: obj })
                history.push("/video-set")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

export const onFetchContent = (data, setState, setLoader, history) => (dispatch, getState) => {
    commonAxios("get-video-sets", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                history.push(`/create-videoset`)
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSubmitVideoSet = (data, setLoader, history) => (dispatch, getState) => {
    commonAxios("save-video-sets", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                history.push(`video-set`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onPlatform = (setState) => (dispatch, getState) => {
    commonAxios("fetch-platform", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchVideos = (data, setVideoData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-videos", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setVideoData(res.data)
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const getContent = (setState, setLoader, setTotalCourses, showRecord) => (dispatch, getState) => {
    commonAxios("fetch-campaigns-all", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (showRecord) {
                    arr = arr.slice(0, showRecord)
                }
                setState(arr)
                if (setTotalCourses) {
                    setTotalCourses(res.data.length)
                }
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}


export const onDeleteRecord = (data, setButton, setShowDelete, fetchContents) => (dispatch, getState) => {
    commonAxios("delete-campaigns", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchContents()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setButton("Delete")
            setShowDelete(false)

        }).catch((error) => {
            dispatch(setAlert(error.message, "danger"))
            setButton("Delete")
            setShowDelete(false)
            console.log(error)
        })
}


//Editor Stuff

export const onScriptFetch = (videoId, history, state, setState) => (dispatch, getState) => {
    let data = {
        videoId: videoId
    }
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_SCRIPT_DATA", payload: res.data })
                setState({
                    ...state,
                    script: res.data.originalScript.replaceAll("\n", "")
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
                history.push(`/video-list`)
            }
        }).catch((err) => {
            history.push(`/video-list`)
            dispatch(setAlert(err.msg, "danger"))
            console.log((err))
        })
}

export const onFetchVideo = (videoId, history, setLoader) => (dispatch, getState) => {
    let data = {
        videoId: videoId
    }
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_VIDEO_DATA", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
                history.push(`/video-list`)
            }
            setLoader(false)
        }).catch((err) => {
            history.push(`/video-list`)
            dispatch(setAlert(err.msg, "danger"))
            console.log((err))
        })
}
export const onHandleContent = (data, history, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type === "save") {
                    setLoader({ ...loader, saveLoader: false })
                    dispatch(setAlert(res.msg, "success"))
                } else {
                    setLoader({ ...loader, saveLoader: false })
                    history.push(`/editor?id=${data.videoId}`)
                }
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({
                    ...loader,
                    saveLoader: false,
                    generateLoader: false
                })
            }
        }).catch((error) => {
            setLoader({
                ...loader,
                saveLoader: false,
                generateLoader: false
            })
            console.log(error)
        })
}


export const onFetchFont = (setFonts, fontNotSet) => (dispatch) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            WebFont.load({
                google: {
                    families: arr
                }
            });
            setFonts(arr);
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onSearchMedia = (data, state, setState, setLoader, search, setSearch, page, setError) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...state];
                setState(arr.concat(res.data));

            } else {
                setError(`Oops, No data found for the Keyword "${data.keyword}"`);
            }
            setLoader(false);
            setSearch({ ...search, page: page });
        }).catch(err => {
            setLoader(false);
            console.log(err)
        })
}

export const onGenerateFile = (data, setLoader, setOutput) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutput(res.data);
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false);
        }).catch(err => {
            console.log(err)
            setLoader(false);
        })
}

export const onVideoCrop = (formData, setLoader, setOutputVideo, setOutputThumb) => (dispatch, getState) => {
    commonAxios("video-crop", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}

export const onFetchGifs = (data, setGifs, setLoader, setError) => (dispatch, getState) => {
    commonAxios("load-gif", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setGifs(JSON.parse(res.data).data)
            } else {
                setError(`Oops, No data found for the Keyword "${data.types}"`);
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}

export const onTemplateFetch = (setState, setTempLoader, memberShip) => (dispatch, getState) => {
    commonAxios("fetch-template", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = [...res.data]
                if (memberShip[memberShip.length - 1] === "premium") {
                    data = data.splice(0, 5)
                }
                if (memberShip[memberShip.length - 1] === "professional") {
                    data = data.splice(0, 10)
                }
                setState(data)
            }
            setTempLoader(false)
        }).catch((err) => {
            console.log(err)
            setTempLoader(false)
        })
}
export const getTempVarient = (id, setState, setLoader) => (dispatch, getState) => {
    let data = {
        templateId: id
    }
    commonAxios("fetch-template-variation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onFecthAnim = (setState) => (dispatch, getState) => {
    commonAxios("fetch-animation", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onUpload = (data, fetchMedia, setLoader, setPercent) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    }
    setLoader(true)
    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchMedia()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const getUploadData = (data, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
            else {
                setData(res.data)
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetchLoader: false
                })
            }
        }).catch((error) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetchLoader: false
                })
            }
            console.log(error)
        })
}

export const onAudioFetch = (data, audioData, setAudioData, setPageCount, setError, setLoader, page) => (dispatch, getState) => {
    commonAxios("load-library-audio", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setAudioData(audioData.concat(res.data));
                setPageCount(page);
            } else {
                setError(res.data.message);
            }
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            console.log(err)
        })
}

export const onGenerateTts = (data, generateData, setGenerateData, setLoader, setTtsStatus) => (dispatch, getState) => {
    commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTtsStatus(false)
                setGenerateData({
                    ...generateData,
                    url: res.data.file_url,
                    duration: res.data.duration - 3
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
                setTtsStatus(true)
            }
            setLoader(false);
        }).catch((error) => {
            setTtsStatus(true)
            setLoader(false);
            console.log(error)
        })
}

export const onGenerateTtsAll = (slideData, ttsLang, setDeactiveButton) => async (dispatch, getState) => {
    dispatch(setAlert("Please wait, We will inform you once the voiceover generated for all the slides", "warning"))
    let arr = [...slideData]
    let i = 0
    for (const curElem of arr) {
        let text = ""
        // if (curElem.layers) {
        //     await curElem.layers.forEach(async (layer) => {
        //         if (layer.src !== "" && layer.type === "text") {
        //             text += await layer.src
        //             text += "\n"
        //         }
        //     })
        // }
        if (curElem.layers) {
            for (const layer of curElem.layers) {
                if (layer.src !== "" && layer.type === "text") {
                    text += await layer.src
                    text += "\n"
                }
            }
        }
        const data = {
            engine: ttsLang.engine,
            language_id: ttsLang.language_id,
            voice_id: ttsLang.voice_id,
            text: text
        }
        if (data.text !== "") {
            await commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
                .then(async (res) => {
                    if (res.status) {
                        let obj = {
                            slideIndex: i,
                            url: res.data.file_url,
                            duration: res.data.duration - 3,
                            ttsData: data
                        }
                        await dispatch({ type: "ADD_TTS_IN_ALL", payload: obj })
                    }
                    if (i === slideData.length - 1) {
                        dispatch(setAlert("Voiceover generated for all slides", "success"))
                        setDeactiveButton(false)
                    }

                }).catch((error) => {
                    console.log(error)
                })
        } else {
            if (i === slideData.length - 1) {
                dispatch(setAlert("Voiceover generated for all slides", "success"))
                setDeactiveButton(false)
            }
        }
        i++
    }
}

export const onFetchLang = (pollyLanguages, setPollyLanguages, memberShip) => (dispatch, getState) => {
    commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                if (memberShip[memberShip.length - 1] === "professional") {
                    arr = arr.splice(0, 21)
                } else if (memberShip[memberShip.length - 1] === "professional-lite") {
                    arr = arr.splice(0, 11)
                } else if (memberShip[memberShip.length - 1] === "bundle") {

                } else {
                    arr = res.data.filter((curElem) => {
                        return (curElem.name.includes("English") || curElem.name.includes("French") || curElem.name.includes("Hindi") || curElem.name.includes("Spanish"))
                    })
                }
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                setPollyLanguages({ ...pollyLanguages, data: arr });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchVoice = (value, pollyVoices, setPollyVoices, setLangData, langData) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('lang_code', value);
    let id = ["96"]
    commonAxios("get-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = [...res.data]
                obj = obj.filter((curElem) => {
                    return !id.includes(curElem.id)
                })
                setPollyVoices({ ...pollyVoices, data: obj });
                setLangData({
                    ...langData,
                    voice: "",
                    lang: value
                })
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGoogleLangFetch = (setTransLang, memberShip) => (dispatch, getState) => {
    commonAxios("fetch-google-language", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                arr = arr.filter((curElem) => {
                    return parseInt(curElem.id) !== 40
                })

                if (memberShip[memberShip.length - 1] === "professional-lite") {
                    arr = arr.splice(0, 10)
                }
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                setTransLang(arr);
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onTranslate = (data, setTranslate, translate, selectedSlideIndex) => (dispatch, getState) => {
    commonAxios("translate", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTranslate({
                    ...translate,
                    transText: res.data.text,
                    loader: false
                })
                dispatch(updateTransLateText(res.data.text, selectedSlideIndex))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
                setTranslate({
                    ...translate,
                    loader: false
                })
            }
        }).catch((err) => {
            setTranslate({
                ...translate,
                loader: false
            })
            console.log(err)
        })
}

export const onFetchSonority = (setMusicData, setLoader, socialToken) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: 'https://regis12.amzitrage.com/fetch-all',
        data: {},
        headers: { 'Content-Type': 'application/json', 'Authorization': socialToken }
    }).then(res => {
        if (res.data.status === true) {
            setMusicData(res.data.data);
        } else {
            dispatch(setAlert(res.data.message, 'danger'));
        }
        setLoader(false);
    }).catch(err => {
        setLoader(false);
    })
}

export const deleteTile = (slide, index, setShowDelete, setButton) => (dispatch, getState) => {
    let data = {
        slideId: slide.id
    }
    commonAxios("delete-slide", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "DELETE_SLIDE", payload: index })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setShowDelete(false)
            setButton("Delete")
        }).catch((err) => {
            console.log(err)
            setShowDelete(false)
            setButton("Delete")
        })
}

export const onUploadAudio = (data, setLoader, setPercent, fetchAudios, mediaType, config) => (dispatch) => {
    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                fetchAudios(mediaType)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader(false)
        }).catch(() => {
            setPercent(0)
            setLoader(false)
        })
}

export const uploadRecording = (data, fetchRecording, setAudioURL, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchRecording()
                setAudioURL('')
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
            }
            else {
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
}

export const onUploadLW = (formData, selectImage, name, setName, setLoadLogo, setLoadWatermark, type) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                selectImage(res.data.path, type)
                res.data.type === "logo" ?
                    setName({
                        ...name,
                        logoName: res.data.name
                    }) :
                    setName({
                        ...name,
                        waterMarkName: res.data.name
                    })

            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }

            setLoadLogo(false)
            setLoadWatermark(false)
        }).catch(() => {
            setLoadLogo(false);
            setLoadWatermark(false);
        })
}

export const onUploadIR = (data, setLoader, type) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = {
                    url: res.data.path,
                    nameData: res.data.name,
                    thumbData: res.data.thumbnail
                }
                if (type === "intro") {
                    dispatch(updateIntroOutro('UPDATE_INTRO', data, setLoader))
                }
                else {
                    dispatch(updateIntroOutro('UPDATE_OUTRO', data, setLoader))
                }
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onDeleteMedia = (formData, setButton, setShowDelete, fetchIntro) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchIntro()
                dispatch(setAlert(res.msg, 'success'));
            }
            setButton("Delete")
            setShowDelete(false);
        }).catch((error) => {
            if (setShowDelete) {
                setShowDelete(false);
            }
            console.log(error)
        })
}

export const onFetchTransition = (setState, setLoader, memberShip) => (dispatch, getState) => {
    commonAxios("fetch-transition", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = [...res.data]
                if (memberShip[memberShip.length - 1] === "unlimited") {
                    data = data.splice(0, 70)
                }
                if (memberShip[memberShip.length - 1] === "unlimited-lite") {
                    data = data.splice(0, 30)
                }
                setState(data)
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
}

export const saveVideo = (campaign, setAllowCall) => (dispatch, getState) => {

    let data = { ...campaign }
    delete data.undoRedo
    delete data.lastAction
    setAllowCall(false)
    commonAxios("save-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
            setAllowCall(true)
        }).catch((err) => {
            setAllowCall(true)
            console.log(err)
        })
}

export const onAddSlide = (data, setLoader) => (dispatch, getState) => {
    commonAxios("add-slide", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_NEW_SLIDE", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onRender = (data, setRenderLoader, history) => (dispatch, getState) => {
    commonAxios("save-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                history.push("/video-list")
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))

            }
            setRenderLoader(false)
        }).catch((error) => {
            dispatch(setAlert(error.message, "danger"))
            setRenderLoader(false)
            console.log(error)
        })
}

export const removeVideo = (state, setState, fetchVideos) => (dispatch, getState) => {
    let data = {
        videoId: state.id
    }
    commonAxios("delete-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchVideos()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setState({
                ...state,
                id: -1,
                button: "Delete",
                show: false,
                name: ""
            })
        }).catch((error) => {
            console.log(error)
            setState({
                ...state,
                id: -1,
                button: "Delete",
                show: false,
                name: ""
            })
            dispatch(setAlert(error.message, "danger"))
        })
}

export const onUpdateName = (epName, data, fetchData, setLoader, nameData, setNameData) => (dispatch, getState) => {
    commonAxios(epName, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setNameData({
                ...nameData,
                active: false
            })
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            setLoader(false)
        })
}

export const onCheckStatus = (data, interval, fetchContentData, setStatus) => (dispatch, getState) => {
    commonAxios("check-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let status = res.data[0].status
                if (status === "1" || status === "2") {
                    clearInterval(interval)
                    setStatus(status)
                    fetchContentData()
                } else {
                    setStatus(status)
                }
            }
        })
        .catch((err) => {
            console.log(err)
        })
}

export const onCheckRender = (data, interval, fetchContentData, state, setState) => (dispatch, getState) => {
    commonAxios("check-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let newRes = res.data[0]
                if (newRes.status === "2" || newRes.status === "3") {
                    clearInterval(interval)
                    setState({
                        ...state,
                        cardStatus: newRes.status
                    })
                    fetchContentData()
                }
                else if (newRes.status === "5" || newRes.status === "6" || newRes.status === "7" || newRes.status === "9") {
                    setState({
                        ...state,
                        render: newRes.renderProgress,
                        cardStatus: newRes.status
                    })
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}



export const onDeleteVideoSet = (id, fetchVideoSet, state, setState) => (dispatch, getState) => {
    let data = {
        videoSetId: id
    }
    commonAxios("delete-video-set", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchVideoSet()
                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setState({
                ...state,
                show: false,
                button: "Delete"
            })
        }).catch((err) => {
            console.log(err)
            setState({
                ...state,
                show: false,
                button: "Delete"
            })
            dispatch(setAlert(err.msg, "danger"));
        })
}

export const onUpdateTemplate = (data, history, setLoader) => (dispatch, getState) => {
    commonAxios("preset-update", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLoader(true)
                dispatch(onFetchVideo(data.videoId, history, setLoader))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onVariationChange = (data, slideIndex, setPreLoader) => (dispatch, getState) => {
    setPreLoader(true)
    commonAxios("variation-change", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                let obj = { ...res.data }

                obj.audio = JSON.parse(obj.audio)
                obj.background = JSON.parse(obj.background)
                obj.layers = JSON.parse(obj.layers)
                obj.layers.forEach((layer) => {
                    if (layer.type === "text") {
                        if (!layer.textToHighlight) {
                            layer.textToHighlight = []
                        }

                    }
                    layer.animation.playAnimation = true
                })
                obj.videoTiles = JSON.parse(obj.videoTiles)
                obj.variationId = data.newVariationId
                let dataObj = { obj, slideIndex }

                dispatch({ type: "CHANGE_VARIATION_DATA", payload: dataObj })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPreLoader(false)
        }).catch((err) => {
            console.log(err)
            setPreLoader(false)
        })
}

export const updateScriptWithTrans = (state, setState, slideData) => async (dispatch, getState) => {

    let arr = JSON.parse(JSON.stringify(slideData))
    let i = 0
    for (const curElem of arr) {
        if (curElem.originalScript !== "") {
            let data = {
                lang_code: state.language,
                text: curElem.originalScript
            }
            await commonAxios("translate", data, dispatch, getState().auth.token)
                .then((res) => {
                    if (res.status) {
                        let ttsData = {
                            text: res.data.text,
                            i: i
                        }
                        dispatch({ type: "UPDATE_ORIGINAL_SCRIPT", payload: ttsData })
                    }
                }).catch((err) => {
                    console.log(err)
                })
            if (curElem.layers.length > 0) {
                let j = 0

                for (const layer of curElem.layers) {
                    if (layer.type === "text") {
                        let obj = {
                            lang_code: state.language,
                            text: layer.src
                        }
                        await commonAxios("translate", obj, dispatch, getState().auth.token)
                            .then((res) => {
                                if (res.status) {
                                    let ttsLayerData = {
                                        text: res.data.text,
                                        i: i,
                                        indexVal: j
                                    }
                                    dispatch({ type: "UPDATE_LAYER_ORIGINAL_SCRIPT", payload: ttsLayerData })
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                    }
                    j++
                }
            }
            if (i === arr.length - 1) {
                setTimeout(() => {
                    setState({
                        ...state,
                        loader: false,
                        language: ""
                    })
                }, 1000)
            }
            i++
        }
    }
}

export const onPublishVideo = (type, data, setShow, setLoader) => (dispatch, getState) => {
    commonAxios(type, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setShow(false);
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setShow(false);
            setLoader(false);
        })
}

export const onOCP = (id, ocp, setOcp) => (dispatch, getState) => {
    let data = {
        videoId: id
    }
    commonAxios("fetch-ocp-video-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOcp({
                    ...ocp,
                    loader: false,
                    show: true,
                    data: res.data
                })
            } else {
                setOcp({
                    ...ocp,
                    loader: false,
                })
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            setOcp({
                ...ocp,
                loader: false,
            })
            console.log(err)
        })
}

export const fetchVideoReel = (token, state, setState) => (dispatch) => {
    axios({
        method: 'POST',
        url: 'https://v2server.videoreel.io/fetch-all-projects-gets',
        data: JSON.stringify(token),
        headers: { 'Content-Type': 'application/json', 'Authorization': token.token }
    }).then((res) => {
        if (res.data.status === true) {
            let data = res.data.data
            let introArr, outroArr
            introArr = data.filter((curElem) => {
                return curElem.category === "intro"
            })
            outroArr = data.filter((curElem) => {
                return curElem.category === "outro"
            })
            setState({
                ...state,
                intro: introArr,
                outro: outroArr
            })
        }
    }).catch((error) => {
        console.log(error)
    })
}

export const fetchVideosToMerge = (setState, loader, setLoader, resolution) => (dispatch, getState) => {
    commonAxios("fetch-all-render", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                arr = arr.filter(curElem => {
                    return curElem.dimension === resolution
                })
                setState(arr)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                dataLoader: false
            })
        }).catch((error) => {
            setLoader({
                ...loader,
                dataLoader: false
            })
            console.log(error)
        })
}

export const fetchMergeCampaigns = (setMergeVideo, setTotalCourses, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-merge-campaigns", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTotalCourses(res.data.data.length)
                setMergeVideo(res.data.data);
            }
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            console.log(err)

        })
}

export const onDeleteMerge = (data, fetchMerge, setShowDelete, setButtonText) => (dispatch, getState) => {
    commonAxios("delete-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchMerge();
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setShowDelete(false)
            setButtonText("Delete")
        }).catch((err) => {
            console.log(err)
            setShowDelete(false)
            setButtonText("Delete")
        })
}

// No Axios Here

export const setCampName = (val) => (dispatch) => {
    let obj = {
        campaignName: val.name,
        campaignId: val.id
    }
    dispatch({ type: "SET_CAMPAIGN_NAME", payload: obj })
}

export const openVideoSet = (data) => (dispatch) => {
    dispatch({ type: "SET_VIDEOSET_DATA", payload: data })
}



// Editor 

export const updateScript = (index, slideIndex) => (dispatch) => {
    const data = { index, slideIndex }
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}
export const rearrangeTiles = (slides) => (dispatch) => {
    dispatch({ type: "REARRANGE TILES", payload: slides })
}

export const onFontSize = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTSIZE', payload: temp });
}

export const onFontFamily = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTSTYLE', payload: temp });
}

export const onTextBold = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTBOLD', payload: temp });
}

export const onTextItalic = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTITALIC', payload: temp });
}

export const onTextUpper = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}
export const onTextLower = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}

export const onTextUnderline = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTDECO', payload: temp });
}

export const onTextStrike = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTDECO', payload: temp });
}


export const onTextAlign = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTALIGN', payload: temp });
}

export const onTextLineHeight = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTLINEHEIGHT', payload: temp });
}

export const onFontColor = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTCOLOR', payload: temp });
}

export const onFontBgColor = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTBGCOLOR', payload: temp });
}


export const removeFontBgColor = (slideIndex, textIndex) => (dispatch) => {
    let data = { slideIndex, textIndex }
    dispatch({ type: 'REMOVE_FONTBGCOLOR', payload: data });
}

export const applyToAllSlide = (style, innerCss) => (dispatch) => {
    const data = { style, innerCss }
    dispatch({ type: "APPLY_TO_ALL_LAYERS", payload: data })
}

export const updateBackground = (file, index, type) => (dispatch) => {
    let data = { file, index }
    if (type === "image") {
        dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
    }
    else if (type === "video") {
        dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
    }
    else {
        data = { file, index }
        dispatch({ type: "UPDATE_BG_COLOR", payload: data })
    }
}

export const resizeLayer = (w, h, x, y, slideIndex, layerIndex) => (dispatch) => {
    const data = { w, h, x, y, slideIndex, layerIndex }
    dispatch({ type: "RESIZE_LAYER", payload: data })
}

export const rePositionLayer = (t, l, slideIndex, layerIndex) => (dispatch) => {
    const data = { t, l, slideIndex, layerIndex }
    dispatch({ type: "REPOSITION_LAYER", payload: data })
}
export const updateSelectedLayer = (index, slideIndex) => (dispatch) => {
    const data = { index, slideIndex }
    dispatch({ type: "UPDATE_LAYER", payload: data })
}

export const unselectLayers = (slideIndex) => (dispatch) => {
    dispatch({ type: "UNSELECT_LAYERS", payload: slideIndex })
}

export const addMediaLayer = (val, slideIndex, type, dimension) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let width = 280, height = 150
    if (type === "video") {
        if (val.width > 720 || val.height > 720) {
            width = Math.floor(val.width / 9)
            height = Math.floor(val.height / 9)
        } else {
            width = Math.floor(val.width / 3)
            height = Math.floor(val.height / 3)
        }
    }
    if (dimension === "1080x1080") {
        width = 280
        height = 280
    } else if (dimension === "1080x1920") {
        width = 150
        height = 280
    }

    const data = {
        objData: {
            id: randomId,
            type: type,
            layerType: "custom",
            style: {
                color: "#FFFFFF",
                fontFamily: "Noto Sans",
                background: "",
                fontSize: "17",
                textAlign: "left",
                fontWeight: false,
                fontStyle: false,
                textTransform: "lowercase",
                textDecoration: "none",
                lineHeight: 1,
                opacity: 1
            },
            position: {
                left: 30,
                top: 30
            },
            animation: {
                name: "",
                delay: "0.5s",
                duration: "1s",
                playAnimation: false
            },
            size: {
                width: width ? width : 280,
                height: height ? height : 150
            },
            isSelected: true,
            src: val.url,
            meta: "",
            mute: true
        },
        slideIndex
    }
    dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const textLayerAdd = (slideIndex) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let layer = {
        id: randomId,
        type: "text",
        layerType: "custom",
        src: "You Can Edit Text Here",
        style: {
            color: "#FFFFFF",
            fontFamily: "Noto Sans",
            background: "",
            fontSize: "17",
            textAlign: "center",
            fontWeight: false,
            fontStyle: false,
            textTransform: "",
            textDecoration: "none",
            lineHeight: 1,
            opacity: 1
        },
        position: {
            left: 150,
            top: 200
        },
        animation: {
            name: "",
            delay: "0.5s",
            duration: "1s",
            playAnimation: false
        },
        size: {
            width: 300,
            height: 50
        },
        textToHighlight: [],
        isSelected: true,
        meta: "",
    }
    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}


export const updateMediaLayer = (val, slideIndex, layerIndex, type) => (dispatch) => {
    if (type === "image") {
        val = val.url
        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_IMAGE", payload: data })
    } else {
        if (val.width > 720 || val.height > 720) {
            val.width = Math.floor(val.width / 9)
            val.height = Math.floor(val.height / 9)
        } else {
            val.width = Math.floor(val.width / 3)
            val.height = Math.floor(val.height / 3)
        }

        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_VIDEO", payload: data })
    }
}

export const updateAnimation = (val, slideIndex, layerIndex, name) => (dispatch) => {
    let data = { val, slideIndex, layerIndex, name }
    dispatch({ type: "UPDATE_ANIMTION", payload: data })
}

export const endAnimation = (slideIndex, layerIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "END_ANIMATION", payload: data })
}

export const addBgMusic = (url, slideIndex, dur) => (dispatch) => {
    const data = { url, slideIndex, dur }
    dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
    dispatch(setAlert("Music applied successfully !", "success"))
}

export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {
    dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })
    dispatch(setAlert("Music removed successfully !", "success"))
}
export const updateTransLateText = (newText, index) => (dispatch) => {
    const data = { newText, index }
    dispatch({ type: "UPDATE_TEXT_TRANSLATE", payload: data })
}

export const updateTtsText = (text, index) => (dispatch) => {
    const data = { text, index }
    dispatch({ type: "UPDATE_TTS1_TEXT", payload: data })
}

export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}

export const updateTtsUrl = (val, langData, index) => (dispatch) => {
    const data = { val, langData, index }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Voiceover applied successfully', 'success'));
    dispatch(addSlideDuration(val.duration))
}

export const setTtsData = (meta, slideIndex, enable) => (dispatch) => {
    const data = { meta, slideIndex, enable }
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    if (meta.src !== "") {
        dispatch(setAlert('Voiceover applied successfully', 'success'));
        dispatch(addSlideDuration(meta.duration))
    }
}

export const removeTtsFromSlides = (slideIndex) => (dispatch) => {
    const tts = {
        text: "",
        languageId: "",
        voiceId: "",
        translateText: ""
    }
    const data = { tts, slideIndex }
    dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: data })
    dispatch(setAlert("Voiceover removed successfully", "success"))
}


export const textLayerUpdate = (text, slideIndex, layerIndex) => (dispatch) => {
    const data = { text, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}
export const removeLayer = (selectedSlide, slideIndex) => (dispatch) => {
    const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const data = { layerIndex, slideIndex }
    dispatch({ type: "REMOVE_LAYER", payload: data })
}
export const layerDuplicate = (slideIndex, layerData, layerIndex) => (dispatch) => {
    layerData.id = Math.floor(Math.random() * 1000000000000000).toString()
    const data = { slideIndex, layerData, layerIndex }
    dispatch({ type: "COPY_LAYER", payload: data })
}
export const updateLayerPos = (slideIndex, layerIndex, place) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    if (place === "down") {
        dispatch({ type: "LAYER_DOWN", payload: data })
    } else {
        dispatch({ type: "LAYER_UP", payload: data })
    }
}

export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const updateStatus = (enable, type) => (dispatch) => {
    const data = { enable, type }
    dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}
export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}
export const updateLogoWatermark = (type, data, dimension) => (dispatch) => {
    const val = { data, dimension }
    dispatch({ type: type, payload: val });
}
export const bgVolumeChange = (type, value) => (dispatch) => {
    dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
    const data = { value, selectedSlideIndex }
    dispatch({ type: type, payload: data });
}

export const onUndo = (slideId) => (dispatch) => {
    dispatch({ type: "UNDO", payload: slideId })
}
export const onRedo = (slideId) => (dispatch) => {
    dispatch({ type: "REDO", payload: slideId })
}

export const updateLastAction = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_LAST_ACTION", payload: data })
}
export const updatePresent = (data) => (dispatch) => {
    dispatch({ type: "SET_PRESENT", payload: data })
}

export const updateIOStatus = (status, type) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: 'SET_INTRO_STATUS', payload: status })
    } else {
        dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
    }
}

export const removeIO = (type) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: 'REMOVE_INTRO' })
    } else {
        dispatch({ type: 'REMOVE_OUTRO' })
    }
}

export const updateIntroOutro = (type, data, setLoader) => (dispatch) => {

    let au = document.createElement("video")
    au.src = data.url
    au.addEventListener('loadedmetadata', function () {
        let duration = Number((au.duration).toFixed(1));
        data = {
            ...data,
            duration: duration
        }
        setLoader(false)
        dispatch({ type: type, payload: data });
    }, false);


}

export const muteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "CHANGE_VIDEO_MUTE", payload: data })
}

export const unMuteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "CHANGE_VIDEO_UNMUTE", payload: data })
}
export const addTransition = (name, index) => (dispatch) => {
    let data = { name, index }
    dispatch({ type: "ADD_TRANSITION", payload: data })
    dispatch(setAlert("Transition updated successfully !", "success"))
}

export const onUpdateLogoInfo = (height, width) => (dispatch) => {
    let data = { height, width }
    dispatch({ type: "UPDATE_LOGO_HW", payload: data })
}

export const onUpdateWatermarkInfo = (height, width) => (dispatch) => {
    let data = { height, width }
    dispatch({ type: "UPDATE_WATERMARK_HW", payload: data })
}

export const onPlay = (slideIndex) => (dispatch) => {
    dispatch({ type: "ONPLAY_SLIDE", payload: slideIndex })
}

export const revertTranslate = (slideIndex) => (dispatch) => {
    dispatch({ type: "REVERT_TEXT", payload: slideIndex })
}

export const onEditScript = (val) => (dispatch) => {
    dispatch({ type: "EDIT_SCRIPT", payload: val })
}

export const updateTextHeight = (height, slideIndex, layerIndex) => (dispatch) => {
    let data = { height, layerIndex, slideIndex }
    dispatch({ type: "UPDATE_TEXT_HEIGHT", payload: data })
}

export const addInnerStyle = (slideIndex, layerIndex, bg) => (dispatch) => {
    let data = {
        innerCss: {
            display: "inline",
            lineHeight: 2.6,
            background: bg,
            padding: "2%"
        },
        slideIndex, layerIndex
    }
    dispatch({ type: "ADD_INNER_STYLE", payload: data })
}

export const removeInnerStyle = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "REMOVE_INNER_STYLE", payload: data })
}

export const addTextHighlight = (data, slideIndex, layerIndex) => (dispatch) => {
    let obj = { data, slideIndex, layerIndex }
    dispatch({ type: "REPLACE_TEXT_WITH_STYLE", payload: obj })
}

export const updateTextHighlight = (color, type, index, slideIndex, layerIndex) => (dispatch) => {
    let obj = { color, type, index, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_HIGHLIGHT", payload: obj })
}

export const removeHighlightColor = (type, arrIndex, slideIndex, layerIndex) => (dispatch) => {
    let obj = { type, arrIndex, slideIndex, layerIndex }
    dispatch({ type: "REMOVE_HIGHLIGHT", payload: obj })
}

export const onInnerStyle = (layerHTML, slideIndex) => (dispatch) => {
    let data = { layerHTML, slideIndex }
    dispatch({ type: "INNER_STYLE_HTML", payload: data })
}

export const removeTransition = (slideIndex) => (dispatch) => {
    dispatch({ type: "REMOVE_TRANSITION", payload: slideIndex })
}

export const onEnableCtaData = (data) => (dispatch) => {
    dispatch({ type: "ENABLE_PROMO", payload: data })
}

export const onUpdateCtaPos = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_POSITION_PROMO", payload: data })
}

export const onUpdateCta = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_CTA_PROMO", payload: data })
}
